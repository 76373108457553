const localeFotm = $('#form-login').data('locale');
let validEmailLogin;
let validPasswordLogin;
let validFirstRegisterMin;
let validFirstRegisterRegex;
let validLastRegisterMin;
let validLastRegisterRegex;
let validPhoneRegister;
let validEmailRegister;
let validPasswordRegister;
let validPasswordConfRegister;
let validEmailForgotPassword;
let validPasswordReset;
let validPasswordResetConf;

switchPopUp('#url-register', '.popup-form-register', ['.popup-form-login', '.popup-form-forgot']);
switchPopUp('#url-forgot', '.popup-form-forgot', ['.popup-form-register', '.popup-form-login']);
switchPopUp('#url-login', '.popup-form-login', ['.popup-form-register', '.popup-form-forgot']);
switchPopUp('#url-login-forgot', '.popup-form-login', ['.popup-form-register', '.popup-form-forgot']);

showPassword('#password-login-form', '#togglePasswordLogin');
showPassword('#password-register-form', '#togglePasswordRegister');
showPassword('#password-confirmation-register-form', '#togglePasswordConfirmationRegister');
showPassword('#password-form-reset', '#togglePasswordReset');
showPassword('#password-form-reset-confirm', '#togglePasswordResetConfirm');

authForms('submit', '#form-login', 'POST');
authForms('submit', '#form-register', 'POST');
authForms('submit', '#form-forgot', 'POST');
authForms('submit', '#form-reset-password', 'POST');

$('#button-login-form').prop('disabled', true);
$('#button-register-form').prop('disabled', true);
$('#button-forgot-form').prop('disabled', true);
$('#button-reset-password-form').prop('disabled', true);

$(document).on('change keyup paste focus', '.item__input', function(e) {
    let key = e.target.getAttribute('name');
    $('.item__block__border-' + key).removeClass('error-border-custom');
    $('.item__error__message-' + key).empty();
    switch (key) {
        case 'email-login':
        case 'password-login':
            validationLoginForm(key, $(this).val())
            break;
        case 'first-name':
        case 'last-name':
        case 'phone':
        case 'email':
        case 'password':
        case 'password-confirmation':
            validationRegisterForm(key, $(this).val())
            break;
        case 'email-forgot':
            validationForgotPasswordForm(key, $(this).val())
            break;
        case 'password-reset':
        case 'password-reset-confirm':
            validationResetPasswordForm(key, $(this).val())
            break;
    }
});

function switchPopUp(link, fromClass, arrayToClass) {
    $(`${link}`).on( "click", () => {
        $(`${fromClass}`).addClass('active-form')
        arrayToClass.map(item => $(`${item}`).removeClass('active-form'))
    });
}

function showPassword(idPasswordInput, idIcon) {
    const togglePassword = document.querySelector(`${idIcon}`);
    const password = document.querySelector(`${idPasswordInput}`);
    if(togglePassword && password) {
        togglePassword.addEventListener("click", function () {
            const type = password.getAttribute("type") === "password" ? "text" : "password";
            password.setAttribute("type", type);
            this.classList.toggle("fa-eye-slash");
            this.classList.toggle("fa-eye");
        });
    }
}

function authForms(click, tag, method) {
    $(document).on(click, tag, function (event) {
        event.preventDefault();
        $.ajax({
            headers: {
                'X-CSRF-Token': $(this).find('input[name="_token"]').val()
            },
            url: $(this).attr("action"),
            type: `${method}`,
            data: $(this).serialize(),
            success: function (response) {
                if(!response.status && response.type === 'reset-password' ) {
                    showloader();
                    location.reload();
                }
                if(response.errors) {
                    $.each(response.errors, function(key, value) {
                        $(`.item__block__border-${key}`).addClass('error-border-custom');
                        $(`.item__error__message-${key}`).html(value);
                    });
                }else if(response.type === 'forgot-password') {
                    $('#form-forgot').before(`
                        <div id="message-success" style="text-align: center;border-radius: 5px;background-color: #4db84d;color: white;padding: 10px 5px;margin: 10px 0;">
                        ${response.message}
                        </div>`
                    );
                    $('#button-forgot-form').prop('disabled', true);
                    $('#button-forgot-form').removeClass('active-auth-btn');
                    $(`#email-form-forgot`).val('');
                    $(`#email-form-forgot`).prop('disabled', true);
                    $(`#message-success`).on('click', function () {
                        $(this).remove();
                        $(`#email-form-forgot`).removeAttr('disabled');
                    })
                    setTimeout(() => {
                        $(`#message-success`).remove();
                        $(`#email-form-forgot`).removeAttr('disabled');
                    }, 7000)
                }else if(response.type === 'reset-password') {
                    $('#form-reset-password').hide();
                    $('.container-reset-password').append(`
                        <div id="message-success" style="text-align: center;border-radius: 5px;padding: 10px 5px;margin: 10px 0;font-size: 20px;font-weight: bold;display: flex;flex-direction: column;align-items: center;gap: 20px;">
                        <img src="../../img/svg/check.svg" style="background-color: #FF680A;border-radius: 50%;max-width: 16%;">
                        ${response.message}
                        </div>`
                    );
                    setTimeout(() => {
                        location.reload();
                    }, 5000)
                }else {
                    location.reload();
                }
            },
            error: function (response) {
                let errors = $.parseJSON(response.responseText);
                $.each(errors.errors, function(key, value) {
                    $(`.item__block__border-${key}`).addClass('error-border-custom');
                    $(`.item__error__message-${key}`).html(value[0]);
                });
            },
        });
    });
}

function validationLoginForm(key, value) {
    const btnLogin =  $('#button-login-form');
    const itemMessageError =  $(`.item__error__message-${key}`);
    const itemBorderError =  $(`.item__block__border-${key}`);
    switch (key){
        case 'email-login':
            validEmailLogin = validateEmail(value)
            if (!validEmailLogin) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть коректний email' : 'Ввести правильный email');
            }
            break;
        case 'password-login':
            validPasswordLogin = validatePassword(value);
            if (!validPasswordLogin) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум вісім символів' : 'Минимум восемь символов');
            }
            break;
    }
    if (value === '') {
        itemBorderError.removeClass('error-border-custom');
        itemMessageError.empty();
    }

    if (validEmailLogin && validPasswordLogin) {
        btnLogin.removeAttr('disabled');
        btnLogin.addClass('active-auth-btn');
    } else {
        btnLogin.prop('disabled', true);
        btnLogin.removeClass('active-auth-btn');
    }
}

function validationRegisterForm(key, value) {
    const btnRegister =  $('#button-register-form');
    const itemMessageError =  $(`.item__error__message-${key}`);
    const itemBorderError =  $(`.item__block__border-${key}`);
    switch (key){
        case 'first-name':
            validFirstRegisterRegex = validateNameRegex(value)
            validFirstRegisterMin = validateName(value);
            if (!validFirstRegisterRegex) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть ім\'я кирилицею' : 'Введите имя кириллицей');
            }
            if (validFirstRegisterRegex && !validFirstRegisterMin) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум два символи' : 'Минимум два символа');
            }
            break;
        case 'last-name':
            validLastRegisterRegex = validateNameRegex(value);
            validLastRegisterMin = validateName(value);
            if (!validLastRegisterRegex) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть прізвище кирилицею' : 'Введите фамилию кириллицей');
            }
            if (validLastRegisterRegex && !validLastRegisterMin) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум два символи' : 'Минимум два символа');
            }
            break;
        case 'phone':
            validPhoneRegister = validatePhone(value);
            if (!validPhoneRegister ) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть правильний номер телефону' : 'Введите правильный телефонный номер');
            }
            break;
        case 'email':
            validEmailRegister= validateEmail(value)
            if(!validEmailRegister) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть коректний email' : 'Ввести правильный email');
            }
            break;
        case 'password':
            validPasswordRegister = validatePassword(value)
            if(!validPasswordRegister) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум вісім символів' : 'Минимум восемь символов');
            }
            break;
        case 'password-confirmation':
            validPasswordConfRegister = validatePassword(value)
            if(!validPasswordConfRegister) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум вісім символів' : 'Минимум восемь символов');
            }
            break;
    }
    if (value === '') {
        itemBorderError.removeClass('error-border-custom');
        itemMessageError.empty();
    }
    if (validFirstRegisterMin && validFirstRegisterRegex && validLastRegisterMin && validLastRegisterRegex && validPhoneRegister && validEmailRegister && validPasswordRegister && validPasswordConfRegister) {
        btnRegister.removeAttr('disabled');
        btnRegister.addClass('active-auth-btn');
    } else {
        btnRegister.prop('disabled', true);
        btnRegister.removeClass('active-auth-btn');
    }
}

function validationForgotPasswordForm(key, value) {
    const btnForgot =  $('#button-forgot-form');
    const itemMessageError =  $(`.item__error__message-${key}`);
    const itemBorderError =  $(`.item__block__border-${key}`);
    switch (key) {
        case 'email-forgot':
            validEmailForgotPassword = validateEmail(value)
            if (!validEmailForgotPassword) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Введіть коректний email' : 'Ввести правильный email');
            }
            break;
    }
    if (value === '') {
        itemBorderError.removeClass('error-border-custom');
        itemMessageError.empty();
    }
    if (validEmailForgotPassword) {
        btnForgot.removeAttr('disabled');
        btnForgot.addClass('active-auth-btn');
    } else {
        btnForgot.prop('disabled', true);
        btnForgot.removeClass('active-auth-btn');
    }
}

function validationResetPasswordForm(key, value) {
    const btnForgot =  $('#button-reset-password-form');
    const itemMessageError =  $(`.item__error__message-${key}`);
    const itemBorderError =  $(`.item__block__border-${key}`);
    switch (key) {
        case 'password-reset':
            validPasswordReset = validatePassword(value)
            if (!validPasswordReset) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум вісім символів' : 'Минимум восемь символов');
            }
            break;
        case 'password-reset-confirm':
            validPasswordResetConf = validatePassword(value)
            if (!validPasswordResetConf) {
                itemBorderError.addClass('error-border-custom');
                itemMessageError.html(localeFotm === 'ua' ? 'Мінімум вісім символів' : 'Минимум восемь символов');
            }
            break;
    }
    if (value === '') {
        itemBorderError.removeClass('error-border-custom');
        itemMessageError.empty();
    }
    if (validPasswordReset && validPasswordResetConf) {
        btnForgot.removeAttr('disabled');
        btnForgot.addClass('active-auth-btn');
    } else {
        btnForgot.prop('disabled', true);
        btnForgot.removeClass('active-auth-btn');
    }
}

function validateEmail(email) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function validatePhone(phone) {
    return phone.match(
        /^[\+][0-9]{3}[-\s\.]??[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{7,7}$/
    );
}

function validatePassword(password) {
    return password.length >= 8;
}

function validateName(name) {
    return name.length >= 2;
}

function validateNameRegex(name) {
    return name.match(
        /^[А-Яа-яЁёіІїґ][а-яА-ЯЁёіІїґ \'-]*$/
    );
}